<template>
	<div class="container">
		<div class="left-menu" :style="{
        width: !isCollapse ? '12%' : '4.5%',
        textAlign: !isCollapse ? 'left' : 'center',
      }">
			<left-list :leftIndex="'5'" :isCollapse="isCollapse"></left-list>
		</div>
		<div class="right-content" :style="{ width: !isCollapse ? '88%' : '95.5%' }">
			<div class="top-nav">
				<top-header @openMenu="openMenu" :isCollapse="isCollapse"></top-header>
			</div>
			<div class="left-list">
				<div class="left-list-title">
					<span>用户管理</span>
				</div>
				<div class="left-list-item" @click="leftClick('0')">
					<span>用户列表</span>
				</div>
				<div class="left-list-item left-list-active" @click="leftClick('1')">
					<span>用户权限</span>
				</div>
				<!-- <div class="left-list-item" @click="leftClick('1')">
          <span>提现管理</span>
        </div>
        <div class="left-list-item" @click="leftClick('2')">
          <span>余额明细</span>
        </div> -->
			</div>
			<div class="right-next">
				<div class="title">
					<span>用户权限</span>
				</div>
				<div class="date-search">
					<el-button type="primary" @click="userAdd">添加角色</el-button>
				</div>
				<div class="mt-20">
					<el-table :data="tableData.data" border style="width: 100%">
						<el-table-column prop="id" label="ID" width="100" align="center">
						</el-table-column>
						<el-table-column prop="name" label="角色名" width="180" align="center">
						</el-table-column>
						<el-table-column prop="created_at" label="创建时间" width="180" align="center">
						</el-table-column>
						<el-table-column prop="updated_at" label="修改时间" width="180" align="center">
						</el-table-column>
						<el-table-column label="操作" align="center">
							<template slot-scope="scope">
								<el-button size="mini" type="primary" @click="Authority(scope.$index,scope.row)" style="margin-top: 5px">
									权限</el-button>
								<el-button size="mini" type="danger" @click="deleteRoles(scope.$index,scope.row)" style="margin-top: 5px">
									删除</el-button>
							</template>
						</el-table-column>
					</el-table>
				</div>
				<div class="bottom-paging">
					<el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange"
						:current-page="page" :page-sizes="[5, 10, 20, 30, 100]" :page-size="num"
						layout="total, prev, pager, next, sizes" :total="tableData.total">
					</el-pagination>
				</div>
			</div>
		</div>
		<el-dialog title="新增角色" :visible.sync="dialogVisible4" center width="450px">
			<el-input v-model="insertrolesName" placeholder="输入角色名称" style="margin-bottom: 20px;"></el-input>
			<auth-tree :authList="item" :rolesAuthList="[]" :index="index" @AuthChange="authChange" v-for="(item,index) in AuthorityList" :key="index"></auth-tree>
			<span slot="footer" class="dialog-footer">
				<el-button @click="dialogVisible4 = false">取 消</el-button>
				<el-button type="primary" @click="insertRoless">确 定</el-button>
			</span>
		</el-dialog>
		<el-dialog title="权限设置" :visible.sync="dialogVisible3" custom-class="Authority" center width="750px" @close="getList">
			<auth-tree :authList="item" :rolesAuthList="rolesAuthList" :index="index" @AuthChange="authChange" v-for="(item,index) in AuthorityList" :key="index"></auth-tree>
			<span slot="footer" class="dialog-footer">
				<el-button @click="dialogVisible3 = false">取 消</el-button>
				<el-button type="primary" @click="authSetting">确 定</el-button>
			</span>
		</el-dialog>
	</div>
</template>

<script>
	/* eslint-disable */
	import leftList from "../../../components/leftList.vue";
	import topHeader from "../../../components/header.vue"
	import authTree from '../../../components/authtree/authtree.vue'
	export default {
		components: {
			leftList,
			topHeader,
			authTree
		},
		inject: ["reload"],
		data() {
			return {
				AuthorityList:[],
				insertrolesName:'',
				selectedPermissions: [],
				selectedPermissionsList: [],
				dialogVisible3: false,
				dialogVisible4:false,
				tableData: [],
				isCollapse: false,
				page: 1,
				rolesAuthList:[], //该角色以拥有得权限
				rolesID: 0, // 被操作的角色ID
				num: 10
			};
		},
		created() {
			this.getList();
			this.getAuthorityList();
		},
		methods: {
			// 权限
			Authority(index, row) {
				this.$get('front/access/role_upd',{
					role_id: row.id
				}).then(res =>{
					this.AuthorityList = [];
					this.getAuthorityList();
					this.rolesAuthList = res.data.data
					this.selectedPermissionsList = res.data.data;
				})
				this.dialogVisible3 = true;
				this.rolesID = row.id;
			},
			userAdd(){
				this.AuthorityList = [];
				this.getAuthorityList();
				this.dialogVisible4 = true;
			},
			authSetting(){
				this.$post('front/access/role_upd',{
					role_id:this.rolesID,
					permission:this.selectedPermissionsList
				}).then(res =>{
					if (res.data.status == 200) {
						this.$message({
							message: '操作成功！',
							type: 'success'
						});
						this.reload();
					} else {
						this.$message({
							message: res.data.message,
							type: 'warning'
						});
					}
				})
			},
			authChange(val) {
				console.log(val);
				this.selectedPermissions[val.id] = val.val;
				// console.log(this.selectedPermissions);
				this.selectedPermissionsList = [];
				for (let i = 0; i < this.selectedPermissions.length; i++) {
					for (let j = 0; j < this.selectedPermissions[i].length; j++) {
						this.selectedPermissionsList.push(this.selectedPermissions[i][j])
					}
				}
				console.log(this.selectedPermissionsList);
			},
			getAuthorityList() {
				this.$get('front/access/get_permissions', {}).then(res => {
					this.AuthorityList = res.data.data;
					for(let i = 0;i<res.data.data.length;i++){
						this.selectedPermissions.push([]);
					}
					
				})
			},
			//新建角色
			insertRoless() {
				this.$post('front/access/role_add', {
					name: this.insertrolesName,
					permission:this.selectedPermissionsList
				}).then(res => {
					if (res.data.status == 200) {
						this.$message({
							message: '操作成功！',
							type: 'success'
						});
						this.reload();
					} else {
						this.$message({
							message: res.data.message,
							type: 'warning'
						});
					}
				})
			},
			getList() {
				this.$get("front/access/get_role", {
					page: this.page,
					num: this.num
				}).then(res => {
					this.tableData = res.data.data;
					console.log(res);
				})
			},
			leftClick(index) {
				if (index == 0 && this.$route.path != "/user") {
					this.$router.push("/user");
				} else if (index == 1 && this.$route.path != "/userRole") {
					this.$router.push("/userRole");
				} else if (index == 2 && this.$route.path != "/balance") {
					this.$router.push("/balance");
				} else {
					this.reload();
				}
			},
			deleteRoles(index, row) {
				this.rolesID = row.id;
				this.$confirm('确定删除此角色?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning',
					center: true
				}).then(() => {
					this.$post('front/access/role_del', {
						role_id: this.rolesID
					}).then(res => {
						if (res.data.status == 200) {
							this.$message({
								message: '操作成功！',
								type: 'success'
							});
							this.reload();
						} else {
							this.$message({
								message: res.data.message,
								type: 'warning'
							});
						}
					})
				}).catch(() => {
					this.$message({
						type: 'info',
						message: '已取消删除'
					});
				});
			},
			openMenu() {
				this.isCollapse = !this.isCollapse;
			},
			handleSizeChange(val) {
				// console.log(`每页 ${val} 条`);
				this.num = val;
				this.getList();
			},
			handleCurrentChange(val) {
				// console.log(`当前页: ${val}`);
				this.page = val;
				this.getList();
			}
		},
	};
</script>

<style lang="scss" scoped>
	.el-dialog__body {
		.form_item {
			margin-bottom: 20px;

			span {
				width: 120px;
				display: inline-block;
				vertical-align: middle;
				text-align: right;
			}

			.bit {
				span {
					width: 20px;
					margin-right: 5px;
					margin-top: -5px;
					color: red;
				}
			}

			.el-input {
				width: 300px;
				display: inline-block;
				vertical-align: middle;
			}
		}
	}

	.right-content {
		height: 100vh;
		overflow-x: hidden;
		overflow-y: scroll;
		vertical-align: top;
		display: inline-block;

		&::-webkit-scrollbar {
			display: none;
		}

		.date-search {
			margin-top: 30px;
			display: flex;
			justify-content: space-between;

			.searchBtn {
				margin-left: 40px;
				padding: 12px 55px;
			}

			.el-input {
				width: 300px;
			}
		}

		.left-list {
			width: 10%;
			height: 93vh;
			overflow-x: hidden;
			overflow-y: scroll;
			font-size: 14px;
			border-right: 20px solid #f2f2f2;
			display: inline-block;
			text-align: center;

			&::-webkit-scrollbar {
				display: none;
			}

			&-title {
				margin: 20px 0;
				font-size: 16px;
			}

			&-item {
				padding: 15px 0;
				cursor: pointer;
				transition: all 0.2s;

				&:hover {
					color: #fff;
					background-color: #3291f8;
				}
			}

			&-active {
				color: #fff;
				background-color: #3291f8;
			}
		}

		.right-next {
			height: 93vh;
			width: 88.5%;
			overflow-x: hidden;
			overflow-y: scroll;
			border-top: 20px solid #f2f2f2;
			padding: 20px;
			box-sizing: border-box;
			display: inline-block;
			vertical-align: top;

			&::-webkit-scrollbar {
				display: none;
			}
		}

		.title {
			color: #000;
			height: 10px;
			padding-left: 15px;
			line-height: 10px;
			border-left: 5px solid #80c4f8;
			margin-bottom: 10px;
		}

		.top-nav {
			display: flex;
			box-sizing: border-box;
			justify-content: space-between;
			padding: 15px;

			.left-icon {
				i {
					cursor: pointer;
					color: #3291f8;
					font-size: 30px;
				}

				.el-icon-refresh-right {
					margin-left: 30px;
				}
			}

			.right-user {
				font-size: 14px;

				span {
					vertical-align: middle;
					margin-right: 20px;
				}

				.colse-btn {
					cursor: pointer;
					vertical-align: middle;
					border: 1px solid #999;
					display: inline-block;
					padding: 5px 20px;
					border-radius: 50px;

					span {
						margin-right: 10px;
					}

					i {
						vertical-align: middle;
					}
				}
			}
		}
	}

	.left-menu {
		display: inline-block;

		.iconyonghuzu {
			font-size: 18px;
			margin: 0 5px;
		}
	}

	.el-menu {
		width: 100%;
		background-color: #20222a;
		height: 100vh;
	}

	.el-menu-vertical-demo:not(.el-menu--collapse) {
		background-color: #20222a;
		color: #fff;
		height: 100vh;
		width: 100%;
	}

	.el-menu-item:focus,
	.el-menu-item:hover {
		color: #000;
		background-color: #ecf5ff;
	}

	.el-menu-item {
		color: #fff;

		i {
			color: #3291f8;
		}
	}

	.el-menu-item.is-active {
		background-color: #ecf5ff;
		color: #20222a;
	}

	.menu-h5 {
		margin: 20px;
		text-align: center;
		font-size: 20px;
	}

	.menu-icon {
		width: 100%;
		color: #fff;
		box-sizing: border-box;
		padding: 30px 22px;
	}
</style>
