<template>
	<div class="content">
		<div class="auth-list">
			<div class="top-info">
				<span>{{authList.name}}</span>
			</div>
			<div class="category-list">
				<el-tree ref="authTree" :data="authList.children" :default-expand-all="true" node-key="id"
					highlight-current :expand-on-click-node="false" :default-checked-keys="this.rolesAuthList"
					show-checkbox @check-change="authChange">
					<span class="custom-tree-node" slot-scope="{ node, data }">
						<span style="margin-top: 3px;">
							<span style="font-size: 15px;">{{ data.name }} </span>
						</span>
					</span>
				</el-tree>
			</div>
		</div>
	</div>
</template>

<script>
	/* eslint-disable */
	export default {
		props: {
			authList: {
				type: Object,
				default: {}
			},
			index: {
				type: Number,
				default: 0
			},
			rolesAuthList: {
				type: Array,
				default: []
			}
		},
		data() {
			return {
				text:''
			}
		},
		created() {
			console.log(1);
		},
		methods: {
			//权限复选框状态改变触发事件
			authChange(data, checked) {
				// console.log(this.$refs.authTree.getCheckedNodes());
				if (this.$refs.authTree.getCheckedNodes().length == 0) {
					this.$emit('AuthChange', {
						id: this.index,
						val: this.$refs.authTree.getCheckedNodes()
					});
				} else {
					let arr = []
					arr.push(this.authList.id);
					for (let i = 0; i < this.$refs.authTree.getCheckedNodes().length; i++) {
						if (arr.indexOf(this.$refs.authTree.getCheckedNodes()[i].parent_id) == -1) {
							arr.push(this.$refs.authTree.getCheckedNodes()[i].parent_id);
						}
						arr.push(this.$refs.authTree.getCheckedNodes()[i].id);
					}
					this.$emit('AuthChange', {
						id: this.index,
						val: arr
					});
				}
				//authTree
				// console.log(data , checked );
			},
		}
	}
</script>

<style lang="scss" scoped>
	.auth-list {
		background-color: #fff;
		border-radius: 10px;
		margin-bottom: 15px;
		padding-bottom: 15px;

		.top-info {
			padding: 15px;
			display: flex;
			border-bottom: 1px solid #eee;
			justify-content: space-between;
		}

		.category-list {
			padding: 20px 20px;
			padding-bottom: 0;

			.el-checkbox {
				margin-bottom: 20px;
			}
		}
	}
</style>
