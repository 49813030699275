import { render, staticRenderFns } from "./authtree.vue?vue&type=template&id=398c25d4&scoped=true&"
import script from "./authtree.vue?vue&type=script&lang=js&"
export * from "./authtree.vue?vue&type=script&lang=js&"
import style0 from "./authtree.vue?vue&type=style&index=0&id=398c25d4&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "398c25d4",
  null
  
)

export default component.exports